/* eslint-disable @next/next/no-page-custom-font */
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { VFC } from 'react'

import styles from './ReportContainer.module.scss'

type Props = {
  children: React.ReactNode
}

const ReportContainer: VFC<Props> = ({ children }: Props) => {
  const { designSystemReleaseInterFont: isFontMigrationEnabled } = useFlags()
  const latoFont = 'Lato, Helvetica, Arial, sans-serif'

  return (
    <div className={styles.container}>
      {isFontMigrationEnabled === false &&
        <>
          <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet"></link>
          <style>{`
          :root {
            --art-font-family-body: ${latoFont};
            --art-font-family-heading: ${latoFont};
          }
        `}
          </style>
        </>
      }
      {children}
    </div>
  )
}

export default ReportContainer
