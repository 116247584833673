import { useState } from 'react'

import FilePicker from '../FilePicker/FilePicker'
import FileView from '../FileView/FileView'
import styles from  './FileGroup.module.scss'

type Props = {
  onSelectedFilesChanged: (files: File[])=> void
}

const nonDuplicateFiles = (existingFiles: File[], newFiles: File[]) => {
  return {
    duplicates: newFiles.filter(file => existingFiles.find(existing => existing.name === file.name)),
    unique: [...existingFiles, ...newFiles].filter((value, index, self) => index === self.findIndex((t) => (t.name === value.name)))
  }
}

const maybeRenderDuplicatesMessage = (duplicates: string[]) => (
  duplicates.length > 0 && <ul className={styles.warningMessageContainer}>
    <div className={styles.warningMessageLabel}>The following files were already selected.</div>
    { duplicates.map((filename) => (<li key={filename}>{filename}</li>)) }
  </ul>
)

const FileGroup = ({ onSelectedFilesChanged }: Props): JSX.Element => {
  const [files, setFiles] = useState<File[]>([])
  const [duplicateFilenames, setDuplicateFilenames] = useState<string[]>([])

  const onFileSelected = (newFiles: File[]) => {
    const currentFiles = nonDuplicateFiles(files, newFiles)

    setDuplicateFilenames(currentFiles.duplicates.map((file) => file.name))
    setFiles(currentFiles.unique)
    onSelectedFilesChanged(currentFiles.unique)
  }

  const onFileRemoved = async(file: File)=> {
    const currentFiles = files.filter((currentFile) => file !== currentFile)

    setDuplicateFilenames([])
    setFiles(currentFiles)
    onSelectedFilesChanged(currentFiles)
  }

  return (
    <>
      { maybeRenderDuplicatesMessage(duplicateFilenames) }

      <FilePicker onFileSelected={onFileSelected} />

      {files?.map((file) => (
        <FileView key={file.name}
          file={file}
          onRemoved={() => onFileRemoved(file)} />
      ))}
    </>
  )
}

export default FileGroup
