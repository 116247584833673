import { VFC } from 'react'

import ModalContainer, { Props as ModalProps } from '../ModalContainer/ModalContainer'
import { SubmitState } from '../ReportForm/ReportForm'
import styles from './MessageModal.module.scss'

export type ModalContent = {
  title: string
  message: JSX.Element | string
}

type Props = ModalProps & {
  modalContent?: ModalContent
  submitState?: SubmitState
  issueType?: string
}

const MessageModal: VFC<Props> = ({ modalContent, open, setOpen, submitState, issueType }: Props)=> {
  const thankYouMessage = `Thank you, your ${issueType} has been submitted.`

  return submitState !== SubmitState.complete
    ? <ModalContainer open={open} setOpen={setOpen}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={`art-font-heading-xsm ${styles.title}`}>{modalContent?.title}</div>
          <div className={styles.message}>{modalContent?.message}</div>
        </div>
      </div>
    </ModalContainer>
    : <ModalContainer open={open} setOpen={setOpen}>
      <div className={styles.successContainer}>
        <img className={styles.xIcon} src="/images/icons/icon-x.svg" alt="cancel" width={24} height={24} onClick={() => setOpen(false)} />
        <img className={styles.checkmarkIcon} src="/images/icons/icon-checkmark.svg" alt="checkmark" width={48} height={48} />
        <div className={styles.successMessage}>Success</div>
        <div className={styles.thankYouMessage}>{thankYouMessage}</div>
      </div>
    </ModalContainer>
}

export default MessageModal
