/* eslint-disable @next/next/no-img-element */
import { MouseEvent } from 'react'

import styles from './FileView.module.scss'

type Props = {
  onRemoved: (file?: File)=> void
  file?: File
}

const FileView = ({ onRemoved, file }: Props): JSX.Element => {
  const clearCurrentFile = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onRemoved(file)
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerContent}>
        {file?.name}
      </div>

      <button className="iconButton" onClick={clearCurrentFile}>
        <img
          src="/images/icons/icon-cross.svg"
          className="iconButtonIcon"
          alt="remove file"
        />
        <span className="iconButtonText">Remove</span>
      </button>
    </div>
  )
}

export default FileView
