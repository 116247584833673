import { ErrorResponse } from '../model/Responses'

const unwrapResponseBody = async<T, >(response: Response): Promise<T> => {
  if (!response.ok) {
    const errorResponse = ((await response.json()) as ErrorResponse)
    throw new Error(`${response.statusText}, ${errorResponse.message}`)
  }

  return (await response.json() as T)
}

export default unwrapResponseBody
