import { GetServerSideProps } from 'next'
import Head from 'next/head'
import React from 'react'

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import ReportContainer from '../../components/ReportContainer/ReportContainer'
import ReportForm from '../../components/ReportForm/ReportForm'
import { Product } from '../../model/Product'
import { products } from '../api/services/products'
import styles from './index.module.scss'

type Props = {
  product: Product
}

const ProductView = ({ product }: Props ): React.ReactElement | null => {
  return (
    <div className={styles.container}>
      <ReportContainer>
        <Head>
          <title>{product.pageTitle}</title>
        </Head>
        <Header product={product} />
        <ReportForm product={product} />
      </ReportContainer>
      <Footer />
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = async(context) => {
  const { product } = Array.isArray(context.params) ? context.params[0] : context.params
  const found = products.find(productDef => productDef.name === product)

  if (!found) {
    throw 'Not found'
  }

  return {
    props: { product: found }
  }
}

export default ProductView
