import styles from './Footer.module.scss'

const year = new Date().getFullYear()
const wwwUri = process.env.WWW_URI

const Footer = (): React.ReactElement => {
  return (
    <div className={`art-font-body-sm ${styles.footer}`}>
      <span>&copy; {year} Articulate Global, LLC</span>
      <div><a href={`${wwwUri}/360-terms-of-service`}>Terms</a></div>
      <div><a href={`${wwwUri}/trust/privacy`}>Privacy</a></div>
    </div>
  )
}

export default Footer
