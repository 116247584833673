import { FieldValues } from 'react-hook-form'

import { Product } from '../../model/Product'
import { IssueRequestCreate, IssueType } from '../../model/Requests'
import { TIssueResponse } from '../../model/Responses'
import unwrapResponseBody from '../utils'

export const createIssueFromFormData = (formData: FieldValues, product: Product, type: IssueType, attachments: string[], application?: string): IssueRequestCreate => ({
  application,
  type,
  userName: formData.userName,
  email: formData.email,
  summary: formData.issueTitle,
  description: formData.issueDescription,
  product: product.name,
  attachments
})

const createIssueFromResponse = (response: Response) => unwrapResponseBody<TIssueResponse>(response)

export const postIssue = async(createIssueRequest: IssueRequestCreate): Promise<TIssueResponse> => (
  fetch('/api/issue', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(createIssueRequest)
  })
    .then((response) => (
      createIssueFromResponse(response)
    ))
    .catch(() => {
      throw new Error('Failed to create issue.')
    })
)
