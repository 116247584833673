/* eslint-disable @next/next/no-img-element */
import React from 'react'

import { Product } from '../../model/Product'
import styles from './Header.module.scss'

type Props = {
  product: Product
}

const Header = ({ product }: Props): React.ReactElement => {
  return (
    <div className={styles.header}>
      { product.formHeading
        ? (
          <h1 className={styles.formHeadingContainer}>
            <img src={product.headingIcon} alt="heading icon" />
            <span className={styles.formHeading}>{product.formHeading}</span>
          </h1>
        )
        : (
          <img
            src={product.thumbnail}
            alt="Betaportal Logo"
          />
        )
      }
    </div>
  )
}

export default Header
