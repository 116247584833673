/* eslint-disable @next/next/no-img-element */
import { ChangeEvent, useState } from 'react'

import styles from './FilePicker.module.scss'

type Props = {
  onFileSelected: (files: File[])=> void
}

const FilePicker = ({ onFileSelected }: Props): JSX.Element => {
  const [files, setFiles] = useState([])

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    const filesOrEmpty = files?.length
      ? Array.from(files)
      : []

    setFiles([]) // Reset the <input> internal state to avoid 'cached' files.
    onFileSelected(filesOrEmpty)
  }

  return (
    <>
      <label
        htmlFor="fileSelector"
        className={styles.filePickerLabel}
      >
        <div className={styles.selectFilePickerLabelText}>
          Select File(s)
        </div>

        <div
          className="iconButton"
        >
          <img
            className="iconButtonIcon"
            src="/images/icons/icon-upload.svg"
            alt="attach file"
          />
          <strong className="art-font-body-xsm iconButtonText">Browse</strong>
        </div>
      </label>

      <input
        className={styles.filePickerInput}
        id="fileSelector"
        type="file"
        onChange={onFileChange}
        value={files}
        multiple
        hidden
      />
    </>
  )
}

export default FilePicker
