import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { VFC } from 'react-router/node_modules/@types/react'

import styles from './ModalContainer.module.scss'

export type Props ={
  open: boolean
  setOpen: (open: boolean)=> void
}
type ReactChildren ={
  children: React.ReactNode
}
const ModalContainer: VFC<Props & ReactChildren> = ({ children, open, setOpen }) => {
  const ref = useRef<Element | null>(null)

  function close() {
    setOpen(false)
  }

  useEffect(() => {
    ref.current = document.getElementById('app-modal')
  }, [])

  return open && ref.current ? ReactDOM.createPortal(
    <div>
      <div className={styles.modalShadow} onClick={close} />
      <div className={styles.modal}>
        {children}
      </div>
    </div>,
    ref.current)
    : null
}
export default ModalContainer
